section.login-form form div.field-wrapper#newPassword input {
    background-image: url('../../assets/password-icon.svg') !important;
    background-repeat: no-repeat;
    background-position: center left; 
    font-size: 22px;
    letter-spacing: 2px;
}

input.hidden-auto-complete {
    display: none;
}