div.upload-file-list {
    display: none;
}

div.upload-file-list.visible {
    display: block;
}

div.upload-file-list a.remove-all {
    display: none;
    font-size: 12px;
    text-decoration: none;
    color: rgb(0,70,130);
    padding: 15px 22px 8px 22px;
    text-align: center;
}

div.upload-file-list a.remove-all.visible {
    display: block;
}

div.upload-file-list a.remove-all:hover {
    text-decoration: underline;
}

div.upload-file-list ul.file-list {
    list-style: none;
    user-select: none;
    overflow: auto;
}