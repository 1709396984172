li.song-item {
    display: grid;
    grid-template-columns: 25px 1fr 30px;
    border-bottom: solid 1px rgba(255,255,255,0.15);
    align-items: center;
    padding: 10px 22px;
    cursor: pointer;
}

li.song-item:last-child {
    border-bottom: none;
}

li.song-item:hover {
    background-color: rgba(255,255,255,0.1);
}

li.song-item span {
    display: block;
}

li.song-item span.icon {
    background-image: url('../../assets/play-button-white-icon.svg');
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: center left;   
    width: 10px;
    height: 14px;
}

li.song-item span.description em {
    display: block;
    font-style: normal;
}

li.song-item span.description em.title {
    font-size: 15px;
    font-weight: bold;
}

li.song-item span.description em.composer {
    font-size: 13px;
    color: rgba(255,255,255,0.7);
}