li.selectable-item.wix-import.order {
    grid-template-areas: "selectbox photo client ordernumber";
    grid-template-columns: 32px 60px 1fr 90px;
}

li.selectable-item.wix-import.order span.photo {
    position: relative;
}

li.selectable-item.wix-import.order span.photo span.counter {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgb(207,0,0);
    border-radius: 4px;
    padding: 2px 7px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    box-shadow: 2px 1px 3px -1px rgba(0,0,0,0.71);
}

li.selectable-item.wix-import.order span.client {
    grid-area: client;
    padding: 0 20px;
    font-size: 13px;
    word-break: break-all;
}

li.selectable-item.wix-import.order span.order-number {
    grid-area: ordernumber;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
}