em.editable-data {
    display: block;
    font-style: normal;
}

em.editable-data span.editor {
    display: none;
}

em.editable-data.editing span.editor {
    display: block !important;
}

em.editable-data.editing span.display {
    display: none !important;
}

em.editable-data span.editor input {
    background: transparent;
    border: none;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
}