section.song-syncronizer ul {
    list-style: none;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 5px 0;
}

section.song-syncronizer {
    margin-bottom: 75px;
}

section.round-buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 0;
    background: rgba(0,0,0,0.45);
}