div.selectable-list.wix-import.product header ul {
    grid-template-areas: "selectbox photo name collection price";
    grid-template-columns: 32px 60px 1fr 134px;
}

div.selectable-list.wix-import.product.big header ul {
    grid-template-columns: 32px 60px 1fr 134px 60px;
}

div.selectable-list.wix-import.product header ul li.name {
    grid-area: name;
    padding-left: 20px;
}

div.selectable-list.wix-import.product header ul li.collection {
    grid-area: collection;
}

div.selectable-list.wix-import.product header ul li.price {
    grid-area: price;
}