main.password-change {
    padding: 0 22px;
    margin: 0 auto;
    max-width: 960px;
    position: relative;
    display: flex;
    flex-flow: column;
    min-height: 100%;
}

main.password-change div.header-wrapper {
    min-height: 70px;
}

main.password-change div.form-wrapper {
    flex: 2;
    overflow: auto;
    display: flex;
    align-items: center;
}