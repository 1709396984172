section.confirm-popup div.contents {
    text-align: center;
}

section.confirm-popup div.contents img.warn-image {
    display: block;
    margin: 20px auto 0 auto;
}

section.confirm-popup div.contents h3.title {
    margin: 20px 30px 0 30px;
    color: rgba(0,0,0,0.8);
    font-size: 18px;
}

section.confirm-popup div.contents p.message {
    color: rgba(0,0,0,0.6);
    font-size: 14px;
    margin: 15px 30px 0 30px;
}

section.confirm-popup div.contents > button.async-action-button.popup-button {
    width: auto;
    padding: 0 30px;
    margin: 20px auto 30px auto;
}