form.file-picker.draggable {
    margin: 0 22px;
    border: dashed 2px rgba(0,0,0,0.1);
    height: 200px;
    position: relative;
    background-image: url('../../assets/cloud-upload-icon.svg');
    background-repeat: no-repeat;
    background-position: center 20px;
}

form.file-picker.draggable.titled {
    background-position: center 50px;
}

form.file-picker.draggable.titled div.upload-title {
    position: relative;
    margin-top: 5px;
    font-weight: bold;
    z-index: 3;
}

form.file-picker.draggable > input {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    position: absolute;
    color: transparent;
    opacity: 0;
}

form.file-picker.draggable div.upload-click-mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;    
}

form.file-picker.draggable div.upload-text {
    position: absolute;
    bottom: 23px;
    left: 0;
    right: 0;
    z-index: 3;
}

form.file-picker.draggable div.upload-text label {
    display: block;
    text-align: center;
    padding: 2px 0;
}

form.file-picker.draggable div.upload-text label.browse-label {
    font-size: 14px;
    color: rgba(0,0,0,0.51);
    cursor: pointer;
}

form.file-picker.draggable div.upload-text label.browse-label em {
    font-style: normal;
    color: rgb(9, 90, 160);
}

form.file-picker.draggable div.upload-text label.browse-label:hover {
    color: rgba(0,0,0,0.78);
}

form.file-picker.draggable div.upload-text label.browse-label:hover em {
    text-decoration: underline;
}