main.import-platform {
    min-height: 100%;
    padding: 0 !important;
}

main.import-platform > header.client-header {
    margin: 0 22px !important;
}

main.import-platform section.service-list ul {
    list-style: none;
}

main.import-platform section.service-list ul li {
    background-color: rgba(0,0,0,0.03);
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 114px 1fr 78px;
    padding: 10px 0;
    cursor: pointer;
    user-select: none;
}

main.import-platform section.service-list ul li:hover {
    background-color: rgba(0,0,0,0.06);
}

main.import-platform section.service-list ul li span.service-logo {
    text-align: center;
    border-right: solid 1px rgba(0,0,0,0.06);
}

main.import-platform section.service-list ul li span.service-details {
    font-size: 12px;
    padding: 0 12px;
}

main.import-platform section.service-list ul li span.service-details em,
main.import-platform section.service-list ul li span.service-format em {
    display: block;
    font-style: normal;
    font-weight: bold;
    color: rgba(0,0,0,0.7);
    margin-bottom: 3px;
}

main.import-platform section.service-list ul li span.service-details p {
    color: rgba(0,0,0,0.51);
}

main.import-platform section.service-list ul li span.service-format {
    border-left: solid 1px rgba(0,0,0,0.06);
    font-size: 12px;
    text-align: center;
}

main.import-platform section.service-list ul li span.service-format img {
    height: 30px;
}