div.selectable-list.wix-import {
    display: none;
    max-height: 300px;
    overflow-y: auto;
}

div.selectable-list.wix-import::-webkit-scrollbar-track { 
    background-color: rgba(0, 0, 0, 0.08); 
    border-radius: 4px;
    margin: 3px 0 3px 0;
}

div.selectable-list.wix-import header {
    padding: 10px 10px;
    margin: 0 12px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom: solid 1px rgba(0,0,0,0.03);
    font-size: 15px;
}

div.selectable-list.wix-import header ul {
    display: grid;
    grid-auto-flow: column;
    list-style: none;
    font-weight: bold;
}

div.selectable-list.wix-import.big header ul {
    grid-template-columns: 32px 60px 1fr 134px 60px;
}

div.selectable-list.wix-import header ul li.selectbox {
    grid-area: selectbox;
}

div.selectable-list.wix-import header ul li.photo {
    grid-area: photo;
    text-align: center;
}

div.selectable-list.wix-import section ul {
    list-style: none;
}