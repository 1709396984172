section.no-results-display {
    display: none;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-flow: column;
}

section.no-results-display img {
    display: block;
}

section.no-results-display p {
    display: block;
    font-size: 18px;
    text-align: center;
    margin-top: 30px;
}

section.no-results-display p em {
    font-style: normal;
}

section.no-results-display p em.message {
    display: block;
    font-weight: bold;
    color: rgba(0,0,0,0.8);
}

section.no-results-display p em.desc {
    display: block;
    font-size: 13px;
    max-width: 240px;
    margin-top: 10px;
    color: rgba(0,0,0,0.58);
}

section.no-results-display.visible {
    display: flex;
}