button.async-action-button {
    font-family: "Segoe UI semibold";
    border: none;
    display: block;
    width: 283px;
    min-height: 38px;
    border-radius: 24px;
    font-size: 14px;
    color: #fff;
    padding: 3px 0;
    margin-bottom: 10px;
    cursor: pointer;
}

button.async-action-button.hidden {
    display: none !important;
}

button.async-action-button span {
    display: block;
}

button.async-action-button:hover {
    opacity: 0.9;
}

button.async-action-button.blue-theme {
    background-color: rgba(0, 95, 190, 0.92);
}

button.async-action-button.green-theme {
    background-color: rgba(0, 158, 74, 0.92);
}

button.async-action-button.black-theme {
    background-color: rgba(0, 0, 0, 0.57);
}

button.async-action-button.navyblack-theme {
    background-color: rgba(0, 0, 0, 0.92);
}

button.async-action-button.red-theme {
    background-color: rgba(197, 0, 20, 0.92);
}

button.async-action-button span.detail {
    display: none;
    font-size: 10px;
    font-weight: normal;
    color: rgba(255,255,255,0.6);
    padding-bottom: 3px;
}

button.async-action-button span.detail.visible {
    display: block;
}

button.async-action-button.loading span.text {
    display: none;
}

button.async-action-button.loading span.detail {
    display: none;
}

button.async-action-button.loading span.loader {
    display: block;
    margin: 0 auto;
    height: 28px;
    width: 28px;
    background-image: url('../../assets/loader-ring-small.svg');
    background-repeat: no-repeat;
    background-size: contain;
}