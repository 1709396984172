div.importable-wix-items-file:first-child div.importable-file-header {
    border-top: solid 1px rgba(0,0,0,0.07);
}

div.importable-wix-items-file:last-child div.selectable-list.wix-import {
    border-bottom: none !important;
}

div.importable-wix-items-file.loading div.selectable-list.wix-import {
    height: 100px;
    display: block;
}

div.importable-wix-items-file.expanded div.selectable-list.wix-import {
    display: block;
    border-bottom: solid 1px rgba(0,0,0,0.07);
}

div.importable-wix-items-file.selected div.importable-file-header span.select-box,
div.importable-wix-items-file.force-selected div.importable-file-header span.select-box {
    background-image: url('../../assets/checkbox-checked-icon.svg');
    opacity: 1 !important;
}

div.importable-wix-items-file.importing div.importable-file-header span.progress progress {
    display: inline-block;
}

div.importable-wix-items-file.importing div.importable-file-header span.select-box {
    opacity: 0.28 !important;
}

div.importable-wix-items-file.imported div.importable-file-header span.progress progress {
    display: none;
}

div.importable-wix-items-file.imported div.importable-file-header span.progress img.file-complete {
    display: inline-block;
}

div.importable-wix-items-file.failed div.importable-file-header span.progress img.file-alert {
    display: inline-block;
}

div.importable-wix-items-file div.selectable-list.wix-import.loading {
    display: none !important;
}