li.selectable-item.wix-import.product {
    grid-template-areas: "selectbox photo name collection price";
    grid-template-columns: 32px 60px 1fr 139px;
}

li.selectable-item.wix-import.product.big {
    grid-template-columns: 32px 60px 1fr 139px 60px;
}

li.selectable-item.wix-import.product span.name {
    grid-area: name;
    padding: 0 20px;
    font-size: 13px;
}

li.selectable-item.wix-import.product span.collection {
    grid-area: collection;
    align-items: center;
}

li.selectable-item.wix-import.product span.collection span.coll-type {
    display: inline-block;
    padding: 4px;
    background: rgb(0, 0, 0, 0.03);
    border-radius: 4px;
    margin: 4px;
    white-space: nowrap;
    font-size: 12px;
}

li.selectable-item.wix-import.product span.price {
    grid-area: price;
}