section.round-buttons ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

section.round-buttons ul li.round-button {
    width: 48px;
    height: 48px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 4px 4px 8px rgba(0,0,0,0.5);
    cursor: pointer;
    margin: 0 5px;
    opacity: 0.9;
}

section.round-buttons ul li.round-button:hover {
    opacity: 1;
}

section.round-buttons ul li.round-button button.icon {
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    border: none;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

section.round-buttons ul li.round-button.add button.icon {
    background-image: url('../../assets/plus-sign.svg');
}

section.round-buttons ul li.round-button.select button.icon {
    background-image: url('../../assets/select-icon.svg');
    background-size: 30px;
}

section.round-buttons ul li.round-button.delete button.icon {
    background-image: url('../../assets/trash-black-icon.svg');
}

section.round-buttons ul li.round-button.select.selected button.icon {
    background-image: url('../../assets/select-blue-icon.svg');
}

section.round-buttons ul li.round-button.loading button.icon {
    background-image: url('../../assets/ball-loader-silver.svg');
    background-size: 35px;
}