main.wix-csv-import {
    min-height: 100%;
    padding: 0 !important;
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: 70px 1fr;
}

main.wix-csv-import > header.client-header {
    margin: 0 22px !important;
}

main.wix-csv-import div.import-selection,
main.wix-csv-import div.import-results {
    display: none;
}

main.wix-csv-import div.import-selection {
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
} 

main.wix-csv-import div.import-selection.visible {
    display: block;
}

main.wix-csv-import div.import-data-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 100px;
    overflow-y: auto;
    padding-right: 3px;
}

main.wix-csv-import div.import-data-wrapper.unique div.selectable-list.wix-import {
    max-height: calc(100vh - 229.09px) !important;
}

main.wix-csv-import div.buttons-wrapper {
    position: absolute;
    height: 100px;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
}

main.wix-csv-import div.import-selection div.buttons-wrapper div.file-information {
    margin-bottom: 10px;
    display: none;
}

main.wix-csv-import div.import-selection div.buttons-wrapper div.file-information.open {
    display: block;
}

main.wix-csv-import div.import-selection div.buttons-wrapper div.file-information span.msg {
    display: block;
    text-align: center;
    font-size: 13px;
    color: rgba(0,0,0,0.71);
    margin-bottom: 2px;
}

main.wix-csv-import div.import-selection div.buttons-wrapper div.file-information progress {
    display: block;
    width: 250px;
    height: 7px;
    -webkit-appearance: none;
    appearance: none;
}

main.wix-csv-import div.import-selection div.buttons-wrapper div.file-information progress::-webkit-progress-bar {
    background-color: #fff;
    border-radius: 2px;
    border: solid 1px rgba(0,0,0,0.20);
}

main.wix-csv-import div.import-selection div.buttons-wrapper div.file-information progress::-webkit-progress-value {
    background-color: rgba(0,0,0,0.25);
}


main.wix-csv-import div.import-selection div.buttons-wrapper button {
    margin-bottom: 0 !important;
}

main.wix-csv-import div.import-results {
    display: none;
    min-height: 100%;
    grid-auto-flow: row;
    grid-template-rows: 1fr 100px;
}

main.wix-csv-import div.import-results.visible {
    display: grid;
}

main.wix-csv-import div.import-results div.results-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

main.wix-csv-import div.import-results div.results-wrapper img {
    display: block;
    height: 206px;
    margin: 30px auto 16px auto;
}

main.wix-csv-import div.import-results div.results-wrapper span.msg {
    background-color: rgba(3,90,0,0.09);
    padding: 7px;
    font-size: 15px;
    color: rgb(0,67,2);
    text-align: center;
    font-weight: bold;
}

main.wix-csv-import div.import-results div.results-wrapper ul.results-statistics {
    list-style: none;
    margin: 30px auto 0 auto;
    padding-bottom: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

main.wix-csv-import div.import-results div.results-wrapper ul.results-statistics li span {
    display: block;
    text-align: center;
}

main.wix-csv-import div.import-results div.results-wrapper ul.results-statistics li span.quantity {
    font-size: 52px;
    font-weight: bold;
    line-height: 52px;
    border-left: solid 2px rgba(0,0,0,0.05);
}

main.wix-csv-import div.import-results div.results-wrapper ul.results-statistics li span.type {
    font-size: 12px;
    border-left: solid 2px rgba(0,0,0,0.05);
    padding-bottom: 12px;
    text-transform: uppercase;
}

main.wix-csv-import div.import-results div.results-wrapper ul.results-statistics li span.label {
    font-size: 15px;
    color: #fff;
    border-left: solid 2px rgb(255,255,255);
    padding: 6px 9px;
}

main.wix-csv-import div.import-results div.results-wrapper ul.results-statistics li.updated span.label {
    background-color: rgba(232,150,9,0.92);
}

main.wix-csv-import div.import-results div.results-wrapper ul.results-statistics li.imported span.label {
    background-color: rgba(0,133,4,0.6);
}

main.wix-csv-import div.import-results div.results-wrapper ul.results-statistics li.failed span.label {
    background-color: rgba(255,0,0,0.6);
}

main.wix-csv-import div.import-results div.results-wrapper ul.results-statistics li:first-child span {
    border-left: none;
}

main.wix-csv-import div.import-results div.results-wrapper ul.results-statistics li:first-child span.label {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

main.wix-csv-import div.import-results div.results-wrapper ul.results-statistics li:last-child span.label {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

main.wix-csv-import div.import-results div.buttons-wrapper {
    position: unset;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
}

@media (max-width:480px) {

    main.wix-csv-import div.import-data-wrapper.unique div.selectable-list.wix-import {
        max-height: calc(100vh - 288px) !important;
    }

}