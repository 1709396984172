div.simple-loader {
    display: none !important;
    height: 100px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

div.simple-loader.loading {
    display: flex !important;
}

div.simple-loader.startup-loader {
    min-height: 100%;
    height: auto;
    background: #fff;
}

div.simple-loader div.loading-icon {
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: contain;
    width: 80px;
    height: 80px;
}

div.simple-loader div.loading-text {
    font-size: 13px;
    color: rgba(0,0,0,0.6);
    padding-top: 4px;
}

div.simple-loader.colorful-theme div.loading-icon {
    background-image: url('../../assets/ball-loader.svg');
}

div.simple-loader.default-theme div.loading-icon {
    background-image: url('../../assets/ball-loader-silver.svg');
}

div.simple-loader.rowball-theme div.loading-icon {
    background-image: url('../../assets/ellipsis-loader-icon.svg');
    width: 100px;
    height: 40px;
}