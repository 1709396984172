li.song-item.editable {
    cursor: auto;
    grid-template-columns: 25px 1fr 70px;
}

li.song-item.editable span.description em.title {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
}

li.song-item.editable span.description em.composer {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 13px;
    color: rgba(255,255,255, 0.74);
}

li.song-item.editable span.buttons {
    display: flex;
    flex-flow: row;
}

li.song-item.editable span.delete-button {
    width: 30px;
    height: 30px;
    background-image: url('../../assets/trash-white-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px 15px;
    cursor: pointer;
    visibility: hidden;
    opacity: 0.7;
}

li.song-item.editable span.edit-button {
    width: 30px;
    height: 30px;
    background-image: url('../../assets/pencil-white-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 15px 15px;
    cursor: pointer;
    visibility: hidden;
    opacity: 0.7;
}

li.song-item.editable:hover span.edit-button:hover,
li.song-item.editable:hover span.delete-button:hover {
    opacity: 1;
}

li.song-item.editable:hover span.edit-button,
li.song-item.editable:hover span.delete-button {
    visibility: visible;
}

li.song-item.editable.loading span.delete-button {
    background-image: url('../../assets/ball-loader.svg');
    background-size: 18px 21px;
    visibility: visible !important;
}