main.songbook {
    max-width: unset !important;
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    position: relative;
    overflow: hidden;
    background-color: #000;
}

main.songbook section.background {
    position: absolute;
    background-image: url('../../assets/songbooks-bg.jpg');
    background-color: #000;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: blur(23px);
    opacity: 0.8;
    left: -20px;
    right: -20px;
    top: -20px;
    bottom: -20px;
    z-index: 0;
}

main.songbook section.content {
    position: relative;
    z-index: 2;
    min-height: 100%;
}

main.songbook section.content header.client-header {
    margin: 0 22px;
    color: #000 !important;
}