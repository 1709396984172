form.file-picker.draggable.single-pdf {
    border-radius: 8px;
    margin: 6px 6px 0px 6px;
    background-image: none;
    height: auto;
}

form.file-picker.draggable.single-pdf.uploaded {
    display: none;
}

form.file-picker.draggable.single-pdf div.upload-text {
    position: relative;
    bottom: 0;
    padding: 8px;
    display: grid;
    grid-template-columns: 30px 1fr;
}

form.file-picker.draggable.single-pdf div.upload-text span.icon {
    height: 28px;
    background-image: url('../../assets/pdf-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
}

form.file-picker.draggable.single-pdf div.upload-text label {
    cursor: pointer;
    color: rgba(255,255,255,0.85);
    padding-right: 30px;
}

form.file-picker.draggable.single-pdf div.upload-text label:hover {
    color: rgba(255,255,255,1);
}

form.file-picker.draggable.single-pdf div.upload-text span.delete-icon {
    display: none;
    height: 28px;
    background-image: url('../../assets/trash-white-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
}