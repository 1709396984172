li.upload-file span.file-contents {
    padding: 15px 22px;
    background-color: rgba(0,0,0,0.02);
    margin-bottom: 10px;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 24px 1fr 60px;
    align-items: center;
}

li.upload-file:hover span.file-contents {
    background-color: rgba(0,0,0,0.04);
}

li.upload-file span.file-contents span.image img {
    width: 24px;
}

li.upload-file span.file-contents span.info {
    padding-left: 12px;
}

li.upload-file span.file-contents span.info em {
    display: block;
    font-style: normal;
}

li.upload-file span.file-contents span.info em.name {
    font-weight: bold;
    font-size: 15px;
}

li.upload-file span.file-contents span.info em.size {
    font-size: 12px;
    color: rgba(0,0,0,0.6);
}

li.upload-file span.file-contents span.controls {
    text-align: right;
    display: flex;
    justify-items: center;
    justify-content: flex-end;
}

li.upload-file span.file-contents span.controls span.ctrl {
    display: inline-block;
    cursor: pointer;
    background-position: center;
    border-radius: 4px;
}

li.upload-file span.file-contents span.controls span.flow-btn {
    width: 30px;
    height: 26px;
    background-repeat: no-repeat;
    background-size: 20px 16px;
}

li.upload-file span.file-contents span.controls span.delete-btn {
    width: 30px;
    height: 26px;
    background-image: url('../../assets/trash-bin-icon.svg');
    background-repeat: no-repeat;
    background-size: 20px 16px;
}

li.upload-file span.file-contents span.controls span.ctrl-group.upload {
    display: none;
}

li.upload-file span.file-contents span.controls span.pause-btn {
    width: 30px;
    height: 26px;
    background-image: url('../../assets/pause-button-icon.svg');
    background-repeat: no-repeat;
    background-size: 14px 10px;
    margin-bottom: 3px;
}

li.upload-file span.file-contents span.controls span.cancel-btn {
    width: 30px;
    height: 26px;
    background-image: url('../../assets/cancel-button-icon.svg');
    background-repeat: no-repeat;
    background-size: 14px 10px;
    margin-bottom: 3px;
}

li.upload-file span.file-contents span.controls span.ctrl:hover {
    background-color: rgba(0,0,0,0.05);
}

li.upload-file span.file-contents span.controls span.ctrl:active {
    background-color: rgba(0,0,0,0.09);
}

li.upload-file:not(.failed) span.file-contents span.controls span.flow-btn:hover,
li.upload-file:not(.failed) span.file-contents span.controls span.flow-btn:active {
    background-color: unset;
}

li.upload-file span.file-contents span.controls progress {
    display: none;
    width: 60px;
    height: 6px;
    -webkit-appearance: none;
    appearance: none;
}

li.upload-file span.file-contents span.controls progress::-webkit-progress-bar {
    background-color: #fff;
    border-radius: 2px;
    border: solid 1px rgba(0,0,0,0.25);
}

li.upload-file span.file-contents span.controls progress::-webkit-progress-value {
    background-color: #000;
}

li.upload-file span.file-error {
    display: none;
    font-size: 11px;
    color: rgb(192, 0, 0);
    padding: 2px 0 7px 20px;
    text-align: left;
}

/* CLASSES DE ESTADO */

li.upload-file.complete span.file-contents span.controls progress {
    display: none !important;
}

li.upload-file.uploading span.file-contents span.controls {
    justify-content: center;
    flex-flow: column;
    align-items: center;
}

li.upload-file.uploading span.file-contents span.controls span.ctrl-group.upload {
    display: block;
}

li.upload-file.uploading span.file-contents span.controls span.delete-btn,
li.upload-file.completed span.file-contents span.controls span.delete-btn {
    display: none;
}

li.upload-file.uploading.paused span.file-contents span.controls span.pause-btn {
    background-image: url('../../assets/play-button-icon.svg');
}

li.upload-file.uploading span.file-contents:not(.unaccepted) span.controls progress {
    display: inline-block;
}

li.upload-file.completed span.file-contents span.controls span.flow-btn {
    background-image: url('../../assets/green-check-icon.svg');
}

li.upload-file.failed  span.file-contents span.controls span.flow-btn {
    background-image: url('../../assets/red-alert-icon.svg');
}

li.upload-file.failed span.file-contents span.info em.error {
    display: block;
}

li.upload-file.failed span.file-contents {
    margin-bottom: 0;
    padding: 12px 22px 0 22px;
}

li.upload-file.failed span.file-error {
    display: block;
    background-color: rgba(0,0,0,0.02);
    margin-bottom: 10px;
}

li.upload-file.failed:hover span.file-error {
    background-color: rgba(0,0,0,0.04);
}