li.selectable-item {
    list-style: none;
    cursor: pointer;
}

li.selectable-item:hover {
    background-color: rgba(0, 0, 0, 0.02);
}

li.selectable-item span.select-box {
    background-image: url('../../assets/checkbox-unchecked-icon.svg');
    opacity: 0.65;
}

li.selectable-item span.select-box:hover {
    opacity: 1;
}

li.selectable-item.selected {
    background-color: rgb(1, 134, 45, 0.06);
}

li.selectable-item.selected span.select-box {
    background-image: url('../../assets/checkbox-checked-icon.svg');
    opacity: 1 !important;
}

li.selectable-item.selected:hover {
    background-color: rgb(1, 134, 45, 0.1);
}

li.selectable-item.disabled {
    opacity: 0.4;
}