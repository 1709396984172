section.file-uploader.single {
    grid-template-rows: none;
}

section.file-uploader.single.selecting form.file-picker.draggable {
    display: none;
}

section.file-uploader.single.pdf.selecting div.upload-file {
    display: block;
}

section.file-uploader.single.pdf.loading div.upload-file div.icon {
    background-image: url('../../assets/ball-loader.svg');
    background-size: 30px;
}

section.file-uploader.single.pdf.selecting div.upload-file div.pdf-file {
    grid-template-columns: 30px 1fr 70px;
}

section.file-uploader.single.pdf.selecting div.upload-file span.ctrl.delete-btn {
    display: block;
}