* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Segoe UI', Helvetica, sans-serif;
}

::-webkit-scrollbar-button { 
    display: none; 
    width: 4px; 
    border-radius: 0px; 
    background-color: #AAA; 
} 

::-webkit-scrollbar-button:hover { 
    background-color: #AAA; 
} 

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.19); 
    border-radius: 4px;
} 

::-webkit-scrollbar-thumb:hover { 
    background-color: rgba(0, 0, 0, 0.29); 
}

::-webkit-scrollbar-track { 
    background-color: rgba(0, 0, 0, 0.08); 
    border-radius: 4px;
}

::-webkit-scrollbar-track:hover { 
    background-color: rgba(0, 0, 0, 0.1); 
} 

::-webkit-scrollbar { 
    height: 4px;
    width: 4px;
}

div.startup-loader {
    position: absolute;
    z-index: 50;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    align-content: center;
    height: auto !important;
}

div#root > main {
    padding: 0 22px;
    margin: 0 auto;
    max-width: 960px;
    position: relative;
}

section.ui-client-section header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

section.ui-client-section header h3 {
    font-size: 17px;
    color: rgba(0, 0, 0, 0.62);
}

section.ui-client-section header div.search {
    display: flex;
    align-items: center;
}

section.ui-client-section header div.search img {
    cursor: pointer;
}

section.ui-client-section header div.search input {
    border: none;
    margin-right: 8px;
    text-align: right;
}

article.ui-gadget-list {
    display: grid;
    grid-template-columns: 39px 1fr 54px;
    margin: 20px 0;
}

article.ui-gadget-list > img {
    height: 40px;
    border-radius: 7px;
}

article.ui-gadget-list p {
    justify-content: center;
}

article.ui-gadget-list p > em,
article.ui-gadget-list p > small {
    display: block;
}

article.ui-gadget-list p > small {
    font-size: 11px;
}

article.ui-gadget-list p.content-description > em,
article.ui-gadget-list p.content-description > small {
    padding-left: 10px;
}

article.ui-gadget-list p.content-description > em {
    font-family: 'Segoe UI semibold';
    font-style: normal;
}

article.ui-gadget-list p.content-info > small {
    text-align: right;
}

article.ui-gadget-list span.progress {
    display: block;
    width: 100%;
    height: 5px;
    margin: 6px 0 4px 0;
    background-color: rgba(0, 0, 0, 0.06);
}

article.ui-gadget-list span.progress em {
    display: block;
    width: 50%;
    height: 100%;
    background-color: rgba(9, 141, 0, 0.53);
}

article.ui-gadget-list span.buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 3px;
}

article.ui-gadget-list span.buttons img {
    cursor: pointer;
}