section.client-last-shippings header h3 {
    color: rgba(0, 0, 0, 0.62);
}

section.client-last-shippings ul {
    list-style: none;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

section.client-last-shippings ul li {
    padding: 11px;
    background-color: rgba(0, 0, 0, 0.05);
    margin-right: 10px;
    margin-bottom: 5px;
    font-size: 11px;
    border-radius: 7px;
    cursor: pointer;
}

section.client-last-shippings ul li:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

section.client-last-shippings ul li figcaption {
    margin-top: 2px;
    text-align: center;
    max-width: 176px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

section.client-last-shippings ul li img {
    display: block;
    min-height: 140px;
    margin-bottom: 5px;
}