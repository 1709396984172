li.selectable-item.import {
    display: grid;
    grid-template-columns: 30px 40px 1fr 36px;
    align-items: center;
    padding: 8px 22px;
    font-size: 14px;
    border-bottom: solid 1px rgba(0,0,0,0.04);
}

li.selectable-item.import:last-child {
    border-bottom: none;
}

li.selectable-item.import span {
    display: block;
}

li.selectable-item.import span.select-box {
    background-position: left center;
    background-repeat: no-repeat;
    min-height: 13px;
}

li.selectable-item.import span.icon {
    background-image: url('../../assets/import-reg-icon.svg');
    background-position: left center;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 32px;
}

li.selectable-item.import span.import em {
    display: block;
    font-style: normal;
}

li.selectable-item.import span.import em.date {
    font-weight: bold;
    font-size: 15px;
}

li.selectable-item.import span.import em.files {
    font-size: 13px;
    color: rgba(0,0,0,0.54);
}

li.selectable-item.import span.button {
    min-height: 26px;
    opacity: 0.6;
}

li.selectable-item.import:hover span.button {
    background-image: url('../../assets/right-green-arrow.svg');
    background-position: right center;
    background-repeat: no-repeat;
    background-size: contain;
}

li.selectable-item.import:hover span.button:hover {
    opacity: 1;
}