div.importable-file-header {
    padding: 9px 22px;
    background-color: rgba(0,0,0,0.02);
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 32px 24px 1fr 63px;
    align-items: center;
    border-bottom: solid 1px rgba(0,0,0,0.07);
}

div.importable-file-header:hover {
    background-color: rgba(0,0,0,0.05);
    cursor: pointer;
}

div.importable-file-header span.select-box {
    background-image: url('../../assets/checkbox-unchecked-icon.svg');
    background-repeat: no-repeat;
    background-position: left center;  
    display: block;
    height: 100%;
    opacity: 0.65;
}

div.importable-file-header span.select-box:hover {
    opacity: 1;
}

div.importable-file-header span.ext-image img {
    width: 24px;
}

div.importable-file-header span.description {
    padding-left: 15px;
}

div.importable-file-header span.description em {
    display: block;
}

div.importable-file-header span.description em.file-name {
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
}

div.importable-file-header span.description em.data-count,
div.importable-file-header span.description em.selected-count,
div.importable-file-header span.description em.imported-count {
    display: none;
    font-style: normal;
    color: rgba(0,0,0,0.54);
    font-size: 13px;
}

div.importable-file-header span.description em.data-count.visible,
div.importable-file-header span.description em.selected-count.visible,
div.importable-file-header span.description em.imported-count.visible {
    display: block;
}

div.importable-file-header span.progress {
    text-align: right;
    display: flex;
    justify-items: center;
    justify-content: flex-end;
}

div.importable-file-header span.progress progress {
    display: none;
    width: 60px;
    height: 6px;
    -webkit-appearance: none;
    appearance: none;
}

div.importable-file-header span.progress progress::-webkit-progress-bar {
    background-color: #fff;
    border-radius: 2px;
    border: solid 1px rgba(0,0,0,0.25);
}

div.importable-file-header span.progress progress::-webkit-progress-value {
    background-color: #000;
}

div.importable-file-header span.progress img {
    display: none;
}