 /* ####### CABEÇALHO ############ */

main.white-theme {
    color: rgb(255,255,255);
}

main.white-theme header.client-header div.location-wrapper address {
    color: rgba(255,255,255, 0.57);
}

main.white-theme header.client-header div.header-bar h1 {
    color: #fff;
}

main.white-theme header.client-header div.header-bar > a.addr:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

main.white-theme header.client-header div.header-bar > a.addr.menu {
    background-image: url('../assets/menu-icon-white.svg'); 
}

main.white-theme header.client-header div.header-bar > a.addr.back {
    background-image: url('../assets/back-icon-white.svg');
    width: 30px;
    background-position: 5px center;
}

main.white-theme form.file-picker.draggable {
    border-color: rgba(255,255,255,0.2);
}