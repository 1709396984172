section.songbook-header.editable div.details p {
    display: grid;
    grid-template-columns: 1fr 100px;
}

section.songbook-header.editable div.details em.title {
    position: relative;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    border-bottom: solid 1px rgba(255,255,255, 0.15);
    padding-bottom: 4px;
}

section.songbook-header.editable div.details em.artist {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
    font-size: 13px;
    color: rgba(255,255,255, 0.74);
    width: 100%;
    display: block;
}

section.songbook-header.editable div.details em.tracks {
    text-align: right;
}

section.songbook-header div.cover > button {
    display: block;
    background-color: transparent;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/camera-icon-black.svg');
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    opacity: 0.7;
    cursor: pointer;
}

section.songbook-header div.cover > button:hover {
    opacity: 1;
}