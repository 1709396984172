section.song-list {
    margin-top: 12px;
}

section.song-list div.list-title {
    background-color: rgba(0,170,223,0.8);
    padding: 4px 22px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

section.song-list div.list-title::before {
    content:"x";
    visibility: hidden;
    padding: 1px;
  }

section.song-list div.list-title h3 {
    font-size: 14px;
}

section.song-list div.list-title button.delete {
    border: none;
    background-image: url('../../assets/trash-white-icon.svg');
    background-repeat: no-repeat;
    background-size: 12px 15px;
    background-position: center;
    background-color: transparent;
    width: 30px;
    height: 30px;
    cursor: pointer;
    visibility: hidden;
}

section.song-list div.list-title:hover button.delete {
    visibility: visible;
    opacity: 0.8;
}

section.song-list div.list-title:hover button.delete:hover {
    opacity: 1;
}

section.song-list div.list-content {
    margin: 0;
}

section.song-list div.list-content ul {
    list-style: none;
}