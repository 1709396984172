li.song-sync-item {
    background: #fff;
    border-radius: 5px;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.2);
    color: #000;
    margin: 3px;
    display: flex;
    flex-direction: row;
    opacity: 0.9;
    cursor: pointer;
    overflow: hidden;
}

li.song-sync-item:hover {
    opacity: 1;
}

li.song-sync-item span {
    display: block;
    font-size: 16px;
}

li.song-sync-item span.sync-index {
    background-color: rgba(0, 0, 0, 0.13);
    min-width: 50px;
    padding: 8px 0;
    text-align: center;
    font-weight: bold;
}

li.song-sync-item span.sync-time {
    padding: 8px 10px;
}

li.song-sync-item.selected span.sync-index {
    background-color: rgba(0, 88, 175, 1);
    color: #fff;
}

li.song-sync-item.selected span.sync-time {
    color: rgb(0, 71, 143);
}