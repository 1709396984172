section.import-list ul {
    list-style: none;
}

section.import-list ul.list-header {
    display: none;
    background-color: rgba(0,0,0,0.05);
    padding: 0 22px;
    grid-template-columns: 30px 40px 1fr 36px;
    border-bottom: solid 1px rgba(0,0,0,0.06);
    justify-content: center;
}

section.import-list ul.list-header.visible {
    display: grid;
}

section.import-list ul.list-header li {
    display: block;
    padding: 8px 0;
    font-size: 15px;
    font-weight: bold;
}

section.import-list ul.list-header li.checkbox {
    background-image: url('../../assets/checkbox-unchecked-icon.svg');
    background-position: left center;
    background-repeat: no-repeat;
    cursor: pointer;
}

section.import-list.selected ul.list-header li.checkbox {
    background-image: url('../../assets/checkbox-checked-icon.svg');
}