section.popup {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.47);
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

section.popup.open {
    display: flex;
}

section.popup div.close-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

section.popup div.window {
    position: relative;
    z-index: 2;
    margin: 0 40px;
    max-width: 400px;
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 4px 4px 9px rgba(0,0,0,0.4);
}

section.popup div.window button.close-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: none;
    background-image: url('../../assets/popup-close.svg');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    opacity: 0.39;
    cursor: pointer;
}

section.popup div.window button.close-button:hover {
    opacity: 0.80;
}