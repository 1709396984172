button.action-button {
    font-family: "Segoe UI semibold";
    border: none;
    display: block;
    width: 283px;
    height: 38px;
    border-radius: 24px;
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;
    cursor: pointer;
}

button.action-button.hidden {
    display: none !important;
}

button.action-button span {
    display: block;
}

button.action-button:hover {
    opacity: 0.9;
}

button.action-button.blue-theme {
    background-color: rgba(0, 95, 190, 0.92);
}

button.action-button.green-theme {
    background-color: rgba(0, 158, 74, 0.92);
}

button.action-button.black-theme {
    background-color: rgba(0, 0, 0, 0.57);
}