div.async-progress-display {
    margin-bottom: 10px;
    display: none;
}

div.async-progress-display.loading {
    display: block;
}

div.async-progress-display span.msg {
    display: block;
    text-align: center;
    font-size: 13px;
    color: rgba(0,0,0,0.71);
    margin-bottom: 2px;
}

div.async-progress-display progress {
    display: block;
    width: 250px;
    height: 7px;
    -webkit-appearance: none;
    appearance: none;
}

div.async-progress-display progress::-webkit-progress-bar {
    background-color: #fff;
    border-radius: 2px;
    border: solid 1px rgba(0,0,0,0.20);
}

div.async-progress-display progress::-webkit-progress-value {
    background-color: rgba(0,0,0,0.25);
}
