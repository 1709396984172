main.registered-product-imports {
    padding: 0 !important;
    min-height: 100%;
    position: relative;
}

main.registered-product-imports header.client-header {
    margin: 0 22px;
}

main.registered-product-imports section.import-contents {
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 80px;
    overflow: auto;
}

main.registered-product-imports section.buttons-wrapper {
    position: absolute;
    bottom: 0;
    left: 0; 
    right: 0;
    display: flex;
    height: 80px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
}
