main.import-files-selector {
    min-height: 100%;
    padding: 0 !important;
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: 70px 1fr 100px;
}

main.import-files-selector > header.client-header {
    margin: 0 22px;
}

main.import-files-selector div.buttons-wrapper {
    position: absolute;
    height: 100px;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
}