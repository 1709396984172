section#login-wrapper img {
    display: block;
    height: 186px;
    margin: 0 auto 155px auto;
}

section#login-wrapper > a {
    user-select: none;
    text-decoration: none;
}

section#login-wrapper > a > span {
    border-radius: 24px;
    display: block;
    padding: 13px 0 16px 0;
    margin: 15px auto 0 auto;
    font-size: 20px;
    min-width: 283px;
    text-align: center;
    
}

section#login-wrapper span#signup {
    border: solid 2px rgba(0, 0, 0, 0.18);
    color: #707070;
}

section#login-wrapper span#signup:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

section#login-wrapper span#signin {
    background-color: rgba(0, 0, 0, 0.72);
    color: #fff;
}

section#login-wrapper span#signin:hover {
    background-color: rgba(0, 0, 0, 0.65);
}