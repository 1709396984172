li.selectable-item.wix-import {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    padding: 10px 10px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.03);
    margin: 0 12px;
}

li.selectable-item.wix-import:last-child {
    border-bottom: none;
}

li.selectable-item.wix-import span.select-box {
    grid-area: selectbox;
    background-repeat: no-repeat;
    background-position: left center;  
    display: block;
    height: 100%;
}

li.selectable-item.wix-import span.photo {
    grid-area: photo;
}

li.selectable-item.wix-import span.photo img {
    width: 60px;
    border-radius: 35px;
}

li.selectable-item.wix-import.selected {
    border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}

li.selectable-item.wix-import.selected:last-child {
    border-bottom: none;
}

li.selectable-item.wix-import.importing, 
li.selectable-item.wix-import.imported, 
li.selectable-item.wix-import.failed {
    background-color: transparent;
}

li.selectable-item.wix-import.importing:hover, 
li.selectable-item.wix-import.imported:hover, 
li.selectable-item.wix-import.failed:hover {
    background-color: rgba(0, 0, 0, 0.02);
}

li.selectable-item.wix-import.importing span.select-box {
    background-image: url('../../assets/ball-loader-silver.svg');
    background-size: 23px 23px;
    opacity: 1 !important;
}

li.selectable-item.wix-import.imported span.select-box {
    background-image: url('../../assets/green-check-icon.svg');
    background-size: 13px 13px;
    opacity: 1 !important;
}

li.selectable-item.wix-import.failed span.select-box {
    background-image: url('../../assets/red-alert-icon.svg');
    background-size: 13px 13px;
    opacity: 1 !important;
}