section.login-form {
    min-width: 360px;
    max-width: 360px;
    margin: 0 auto;
}

section.login-form img {
    display: block;
    height: 125px;
    margin: 0 auto 35px auto;
}

section.login-form form {
    display: block;
    max-width: 287px;
    margin: 0 auto;
}

section.login-form form div.validation-message {
    height: 26px;
    font-size: 11px;
    color: rgb(255, 0, 0);
}

section.login-form form div.field-wrapper {
    display: flex;
    flex-direction: column;
    border-bottom: solid 1px rgba(0,0,0,0.15);
}

section.login-form form div.field-wrapper.unvalid {
    border-bottom: solid 1px rgba(255,0,0,0.68);
}

section.login-form form div.field-wrapper label {
    font-size: 16px;
    font-weight: bold;
    color: rgba(0,0,0,0.35);
}

section.login-form form div.field-wrapper.unvalid label {
    color: rgba(255, 0, 0, 0.68);
}

section.login-form form div.field-wrapper input {
    margin: 10px 0;
    border: none;
    padding-left: 40px;
    height: 27px;
    font-size: 16px;
    font-weight: bold;
    background-color: transparent !important;
}

section.login-form form div.field-wrapper#name input {
    background-image: url('../../assets/user-icon.svg') !important;
    background-repeat: no-repeat;
    background-position: center left; 
}

section.login-form form div.field-wrapper.unvalid#name input {
    background-image: url('../../assets/user-icon-red.svg') !important;
}

section.login-form form div.field-wrapper#email input {
    background-image: url('../../assets/mail-icon.svg') !important;
    background-repeat: no-repeat;
    background-position: center left; 
}

section.login-form form div.field-wrapper.unvalid#email input {
    background-image: url('../../assets/mail-icon-red.svg') !important;
}

section.login-form form div.field-wrapper#password input,
section.login-form form div.field-wrapper#passwordConfirm input {
    background-image: url('../../assets/password-icon.svg') !important;
    background-repeat: no-repeat;
    background-position: center left; 
    font-size: 22px;
    letter-spacing: 2px;
}

section.login-form form div.field-wrapper.unvalid#password input,
section.login-form form div.field-wrapper.unvalid#passwordConfirm input {
    background-image: url('../../assets/password-icon-red.svg') !important;
}

section.login-form form div.buttons-wrapper {
    min-height: 120px;
}