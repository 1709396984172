div.single-photo-upload-file {
    display: none;
    overflow: hidden;
}

div.single-photo-upload-file div.upload-title {
    padding-top: 5px;
    font-weight: bold;
    color: rgba(0,0,0,0.6);
}

div.single-photo-upload-file div.photo-preview {
    position: absolute;
    top: 33px;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

div.single-photo-upload-file div.photo-preview div.buttons {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

div.single-photo-upload-file div.photo-preview div.buttons span.ctrl {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: rgba(21, 112, 182, 0.7);
    background-repeat: no-repeat;
    background-position: center;
    margin: 10px 5px;
    cursor: pointer;
}

div.single-photo-upload-file div.photo-preview div.buttons span.ctrl:hover {
    background-color: rgba(21, 112, 182, 0.9);
}

div.single-photo-upload-file div.photo-preview div.buttons span.ctrl.flow-btn {
    display: none;
    background-image: url('../../assets/check-white-icon.svg');
}

div.single-photo-upload-file div.photo-preview div.buttons span.ctrl.pause-btn {
    display: none;
    background-image: url('../../assets/pause-button-white-icon.svg');
}

div.single-photo-upload-file div.photo-preview div.buttons span.ctrl.cancel-btn {
    display: none;
    background-image: url('../../assets/close-white-icon.svg');
}

div.single-photo-upload-file div.photo-preview div.buttons span.ctrl.delete-btn {
    background-image: url('../../assets/trash-white-icon.svg');
}

div.single-photo-upload-file div.photo-preview div.error-message {
    display: none;
    margin: 10px;
    padding: 5px;
    background-color: rgba(201,0,0,0.81);
    color: #fff;
    font-size: 13px;
    border-radius: 4px;
}

div.single-photo-upload-file div.photo-progress {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 25px;
    align-items: center;
    justify-content: center;
    display: none;
}

div.single-photo-upload-file div.photo-progress progress {
    width: calc(100% - 20px);
    height: 6px;
    -webkit-appearance: none;
    appearance: none;
}

div.single-photo-upload-file div.photo-progress progress::-webkit-progress-bar {
    background-color: #fff;
    border-radius: 2px;
    border: solid 1px rgba(0,0,0,0.25);
}

div.single-photo-upload-file div.photo-progress progress::-webkit-progress-value {
    background-color: #000;
}

/** CLASSES DE ESTADO **/
div.single-photo-upload-file.complete div.photo-progress {
    display: none !important;
}

div.single-photo-upload-file.uploading div.photo-preview div.buttons span.ctrl.pause-btn,
div.single-photo-upload-file.uploading div.photo-preview div.buttons span.ctrl.cancel-btn {
    display: block;
}

div.single-photo-upload-file.uploading div.photo-preview div.buttons span.ctrl.delete-btn {
    display: none;
}

div.single-photo-upload-file.uploading.paused div.photo-preview div.buttons span.ctrl.pause-btn {
    background-image: url('../../assets/play-button-white-icon.svg');
}

div.single-photo-upload-file.uploading div.photo-preview {
    bottom: 25px;
}

div.single-photo-upload-file.uploading div.photo-progress {
    display: flex !important;
}

div.single-photo-upload-file.completed div.photo-preview div.buttons span.flow-btn {
    display: block;
    background-color: rgba(106,194,89,0.7);
    background-image: url('../../assets/check-white-icon.svg');
}

div.single-photo-upload-file.completed div.photo-preview div.buttons span.ctrl.flow-btn:hover {
    background-color: rgba(106,194,89,0.9);
}

div.single-photo-upload-file.failed div.photo-preview div.buttons span.flow-btn {
    display: block;
    background-color: rgba(209,40,40,0.7);
    background-image: url('../../assets/exclamation-white.icon.svg');
}

div.single-photo-upload-file.failed div.photo-preview div.buttons span.ctrl.flow-btn:hover {
    background-color: rgba(209,40,40,0.9);
}

div.single-photo-upload-file.failed div.photo-preview div.error-message {
    display: block;
}