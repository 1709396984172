main.mail-verification {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

main.mail-verification section {
    max-width: 283px;
    margin: 0 auto;
}

main.mail-verification section figure > img {
    display: block;
    margin: 0 auto;
}

main.mail-verification section figure figcaption {
    margin-top: 50px;
}

main.mail-verification section figure figcaption em {
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    display: block;
    text-align: center;
}

main.mail-verification section figure figcaption p {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.64);
    display: block;
    text-align: center;
    margin-top: 14px;
}

main.mail-verification section div.buttons {
    margin-top: 80px;
}

main.mail-verification section div.buttons div.message {
    display: none;
    color: rgb(221, 0, 0);
    font-size: 12px;
    text-align: center;
    margin: 0 0 15px 0;
}

main.mail-verification section div.buttons div.re-send-message {
    display: none;
    color: rgb(4, 95, 214);
    font-size: 12px;
    text-align: center;
    margin: 0 0 15px 0;
    cursor: pointer;
}

main.mail-verification section div.buttons div.message.open,
main.mail-verification section div.buttons div.re-send-message.open {
    display: block;
}

main.mail-verification section div.buttons div.re-send-message:hover {
    color: rgb(0, 0, 0);
}

main.mail-verification section div.buttons div.message em {
    color: #000;
    font-style: normal;
    font-weight: bold;
}