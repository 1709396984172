article.songbook-item {
    cursor: pointer;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 5px 15px 5px;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
}

article.songbook-item div.edit-tools {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 75px;
    z-index: 4;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

article.songbook-item:hover div.edit-tools {
    display: flex;
}

article.songbook-item div.edit-tools span {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    margin: 5px;
    background-color: rgba(0,0,0, 0.60);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
}

article.songbook-item div.edit-tools span:hover {
    background-color: rgba(0,0,0, 0.80);
}

article.songbook-item div.edit-tools span.edit-button {
    background-image: url('../../assets/pencil-white-icon.svg');
}

article.songbook-item div.edit-tools span.delete-button {
    background-image: url('../../assets/trash-white-icon.svg');
    background-size: 16px;
}

article.songbook-item div.songbook-content {
    display: block;
    border-radius: 5px;
    background-color: rgba(0,0,0, 0.25);
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

article.songbook-item:hover div.songbook-content {
    background-color: rgba(0,0,0, 0.40);
}

article.songbook-item div.songbook-content img.cover {
    display: block;
    width: calc(100% - 16px);
    margin: 8px auto 5px auto;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

article.songbook-item footer .songbook-info {
    color: rgba(255,255,255, 1);
}

article.songbook-item footer .songbook-info span {
    display: block;
    text-align: center;
    text-transform: uppercase;
}

article.songbook-item footer .songbook-info span.songbook-title {
    font-size: 15px;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-bottom: 3px;
}

article.songbook-item footer .songbook-info span.songbook-artist {
    border-top: solid 1px rgba(255,255,255, 0.1);
    font-size: 13px;
    color: rgba(255,255,255, 0.75);
    padding-top: 4px;
    padding-bottom: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

article.new-songbook {
    position: relative;
}

article.new-songbook img.cover {
    visibility: hidden;
}

article.new-songbook div.songbook-content {
    padding-bottom: 53px;
}

article.new-songbook:hover div.songbook-content {
    background-color: rgba(0,0,0, 0.40) !important;
}

article.new-songbook div.add-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

article.new-songbook img.add-button {
    width: 110px;
}

article.new-songbook span.button-text  {
    color: rgba(255,255,255, 0.75);
    font-size: 17px;
}

@media (min-width: 576px) {
    article.songbook-item {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.3333333%;
        flex: 0 0 33.3333333%;
        max-width: 33.3333333%;
    }
}

@media (min-width: 768px) {
    article.songbook-item {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
}

@media (min-width: 1700px) {
    article.songbook-item {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.6666667%;
        flex: 0 0 16.6666667%;
        max-width: 16.6666667%;
    }
}

@media only screen and (max-device-width: 640px) and (max-device-width: 768px) {
    article.new-songbook div.songbook-content {
        padding-bottom: 49px;
    }    
}