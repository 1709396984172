div.selectable-list.wix-import.order header ul {
    grid-template-areas: "selectbox photo client ordernumber";
    grid-template-columns: 32px 60px 1fr 90px;
}

div.selectable-list.wix-import.order header ul li.client {
    grid-area: client;
    padding-left: 20px;
}

div.selectable-list.wix-import.order header ul li.order-number {
    grid-area: ordernumber;
}