section.songbook-header {
    display: grid;
    grid-template-areas: "cover details";
    grid-template-columns: 31px 1fr;
    align-items: center;
    margin: 0 22px;
}

section.songbook-header div.cover {
    grid-area: cover;
    background-image: url('../../assets/sb-no-cover-bg.svg');
    background-repeat: no-repeat;
    background-size: contain;
    height: 42px;
}

section.songbook-header div.details {
    grid-area: details;
    padding-left: 10px;
}

section.songbook-header div.details h3.title {
    font-size: 16px;
    padding-bottom: 3px;
    border-bottom: solid 1px rgba(255,255,255, 0.15);
}

section.songbook-header div.details p {
    color: rgba(255,255,255, 0.69);
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    padding-top: 2px;
}

section.songbook-header div.details p em {
    display: block;
    font-style: normal;
}