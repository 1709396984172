main.my-products {
    max-width: unset !important;
    padding: 0 !important;
 }

main.my-products > header.client-header {
    margin: 0 22px !important;
}

main.my-products div.products-content {
    display: block;
    margin: 0 5px 0 5px;
    height: calc(100vh - 75px);
    padding: 5px;
    overflow-y: auto;
}