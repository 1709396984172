div.simple-messages {
    padding: 6px 7px 7px 7px;
    font-size: 13px;    
    background: transparent;
    color: #fff;
    text-align: center;
    border-radius: 8px;
    transition: background 0.5s;
    min-height: 30px;
}

div.simple-messages.error {
    background: #E41515;
    margin-bottom: 20px;
}

div.simple-messages.msg {
    background: #1BA40C;
    margin-bottom: 20px;
}