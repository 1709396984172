li.product-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 5px 15px 5px;
}

li.product-item span.contents {
    display: block;
    border: solid 1px rgba(0,0,0,0.05);
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

li.product-item span.contents:hover span.background {
    position: absolute;
    left: -15px;
    top: -15px;
    right: -15px;
    bottom: -15px;
    background-size: contain;
    filter: blur(23px);
}

li.product-item span.contents:hover span.foreground {
    display: block;
    position: relative;
    z-index: 10;
}

li.product-item span.ph-display {
    display: block;
    overflow: visible;
    position: relative;
}

li.product-item span.ph-display span.flt {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

li.product-item span.contents img.photo {
    display: block;
    width: 100%;
}

li.product-item span.contents img.hovercard {
    display: none;
    margin: 0 auto;
    height: calc(100% - 51px);
    border-radius: 50%;
}

li.product-item span.contents a.link {
    display: none;
    font-family: Arial;
    background-color: rgb(47,196,57);
    width: 75px;
    margin: 16px auto 0 auto;
    text-align: center;
    padding: 7px 0;
    color: #fff;
    border-radius: 5px;
    font-weight: bold;
    text-decoration: none;
}

li.product-item span.contents a.link:hover {
    text-decoration: underline;
}

li.product-item span.contents em.name {
    display: block;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    margin-top: 10px;
    padding: 0 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
}

li.product-item span.contents span.collections {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px 0;
}

li.product-item span.contents span.collections span.coll-type {
    display: block;
    padding: 3px 6px 4px 6px;
    background: rgb(0, 0, 0, 0.11);
    border-radius: 4px;
    margin: 4px;
    white-space: nowrap;
    font-size: 12px;
    color: rgba(0,0,0,0.6);
    cursor: pointer;
}

li.product-item span.contents span.collections span.coll-type:hover {
    background: rgb(0, 0, 0, 0.6) !important;
    color: #fff !important;
}

li.product-item span.contents:hover span.collections {
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.31) 100%);
}

li.product-item span.contents:hover span.collections span.coll-type {
    background: rgb(255, 255, 255, 0.41);
    color: rgba(0,0,0,0.8);
}

li.product-item span.contents:hover span.background {
    display: block;
}

li.product-item span.contents:hover img.photo {
    visibility: hidden;
}

li.product-item span.contents:hover img.hovercard {
    display: block;
}

li.product-item span.contents:hover a.link {
    display: block;
}

li.product-item.videoaulaspratica span.contents:hover em.name,
li.product-item.videoaulasteoria span.contents:hover em.name {
    color: #fff;
}

li.product-item.videoaulaspratica span.contents:hover span.background,
li.product-item.videoaulasteoria span.contents:hover span.background {
    filter: blur(23px) brightness(70%);
}

@media (min-width: 576px) {
    li.product-item {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.3333333%;
        flex: 0 0 33.3333333%;
        max-width: 33.3333333%;
    }
}

@media (min-width: 768px) {
    li.product-item {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
}

@media (min-width: 1700px) {
    li.product-item {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.6666667%;
        flex: 0 0 16.6666667%;
        max-width: 16.6666667%;
    }
}