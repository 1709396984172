div.upload-file.pdf {
    overflow: hidden;
    display: none;
}

div.upload-file.pdf div.pdf-file {
    display: grid;
    grid-template-columns: 30px 1fr 40px;
    border-radius: 8px;
    margin: 6px 6px 0px 6px;
    padding: 7px;
    border: solid 2px rgba(255,255,255,0.2);
    align-items: center;
}

div.upload-file.pdf div.pdf-file div.icon {
    height: 28px;
    background-image: url('../../assets/pdf-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
}

div.upload-file.pdf div.pdf-file div.upload-title {
    display: block;
    font-weight: bold;
    color: rgb(255,255,255);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    align-items: center;
    font-size: 16px;
    padding: 0 15px;
    text-align: center;
}

div.upload-file.pdf div.pdf-file div.buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

div.upload-file.pdf div.pdf-file div.buttons span.ctrl {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 1px;
    cursor: pointer;
}

div.upload-file.pdf div.pdf-file div.buttons span.ctrl:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

div.upload-file.pdf div.pdf-file div.buttons span.ctrl.upl-btn {
    display: block;
    background-image: url('../../assets/upload-white-icon.svg');
}

div.upload-file.pdf div.pdf-file div.buttons span.ctrl.flow-btn {
    display: none;
    background-image: url('../../assets/check-white-icon.svg');
}

div.upload-file.pdf div.pdf-file div.buttons span.ctrl.pause-btn {
    display: none;
    background-image: url('../../assets/pause-button-white-icon.svg');
}

div.upload-file.pdf div.pdf-file div.buttons span.ctrl.cancel-btn {
    display: none;
    background-image: url('../../assets/close-white-icon.svg');
}

div.upload-file.pdf div.pdf-file div.buttons span.ctrl.delete-btn {
    display: none;
    background-image: url('../../assets/trash-white-icon.svg');
}

div.upload-file.pdf div.pdf-file div.error-message {
    display: none;
    margin: 10px;
    padding: 5px;
    background-color: rgba(201,0,0,0.81);
    color: #fff;
    font-size: 13px;
    border-radius: 4px;
}

div.upload-file.pdf div.pdf-progress {
    height: 14px;
    align-items: center;
    justify-content: center;
    display: none;
}

div.upload-file.pdf div.pdf-progress progress {
    width: calc(100% - 15px);
    height: 6px;
    -webkit-appearance: none;
    appearance: none;
}

div.upload-file.pdf div.pdf-progress progress::-webkit-progress-bar {
    background-color: transparent;
    border-radius: 4px;
    border: solid 1px rgba(255,255,255, .25);
}

div.upload-file.pdf div.pdf-progress progress::-webkit-progress-value {
    background-color: #fff;
    border-radius: 4px;
}

/** CLASSES DE ESTADO **/

div.upload-file.pdf.uploaded {
    display: block;
}

div.upload-file.pdf.uploading div.pdf-file {
    grid-template-columns: 30px 1fr 82px;
}

div.upload-file.pdf.complete div.pdf-progress {
    display: none !important;
}

div.upload-file.pdf.uploading div.pdf-file div.buttons span.ctrl.pause-btn,
div.upload-file.pdf.uploading div.pdf-file div.buttons span.ctrl.cancel-btn {
    display: block;
}

div.upload-file.pdf.uploading div.pdf-file div.buttons span.ctrl.delete-btn {
    display: none;
}

div.upload-file.pdf.uploading.paused div.pdf-file div.buttons span.ctrl.pause-btn {
    background-image: url('../../assets/play-button-white-icon.svg');
}

div.upload-file.pdf.uploading div.pdf-progress {
    display: flex !important;
}

div.upload-file.pdf.completed div.pdf-file div.buttons span.flow-btn {
    display: block;
    background-color: rgba(106,194,89,0.7);
    background-image: url('../../assets/check-white-icon.svg');
}

div.upload-file.pdf.completed div.pdf-file div.buttons span.ctrl.flow-btn:hover {
    background-color: rgba(106,194,89,0.9);
}

div.upload-file.pdf.failed div.pdf-file div.buttons span.flow-btn {
    display: block;
    background-color: rgba(209,40,40,0.7);
    background-image: url('../../assets/exclamation-white.icon.svg');
}

div.upload-file.pdf.failed div.pdf-file div.buttons span.ctrl.flow-btn:hover {
    background-color: rgba(209,40,40,0.9);
}

div.upload-file.pdf.uploading div.pdf-file div.buttons span.ctrl.upl-btn,
div.upload-file.pdf.uploaded div.pdf-file div.buttons span.ctrl.upl-btn,
div.upload-file.pdf.completed div.pdf-file div.buttons span.ctrl.upl-btn,
div.upload-file.pdf.failed div.pdf-file div.buttons span.ctrl.upl-btn {
    display: none;
}

div.upload-file.pdf.uploaded div.pdf-file div.buttons span.ctrl.delete-btn {
    display: block;
}

div.upload-file.pdf.failed div.pdf-file div.error-message {
    display: block;
}