section.file-uploader.single-photo div.single-photo-upload-file {
    color: #000;
    min-height: 279px;
    position: relative;
    border: dashed 2px rgba(0,0,0,0.1);
    border-radius: 6px;
}

section.file-uploader.single-photo form {
    margin: 0;
    padding: 0;
    min-height: 279px;
    color: #000;
    border-radius: 6px;
}

section.file-uploader.single-photo form label.drag-label,
section.file-uploader.single-photo form label.browse-label {
    padding: 0 22px;
}

section.file-uploader.single-photo form label.browse-label {
    margin-top: 7px;
}

section.file-uploader.single-photo.previewing form.file-picker.draggable {
    display: none;
}

section.file-uploader.single-photo.previewing div.single-photo-upload-file {
    display: block;
}