header.client-header {
    display: block;
}

header.client-header div.location-wrapper {
    position: relative;
}

header.client-header div.location-wrapper address {
    position: absolute;
    left: 50px;
    right: 50px;
    text-align: center;
    text-transform: uppercase;
    font-style: normal;
    font-size: 12px;
    padding: 6px 0;
    color: rgba(0,0,0,0.31);
    user-select: none;
}

header.client-header div.header-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    user-select: none;
}

header.client-header div.header-bar img {
    cursor: pointer;

}

header.client-header div.header-bar > a.addr {
    display: block;
    text-decoration: none;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center center;
    opacity: 0.7;
    border-radius: 5px;
    width: 32px;
    height: 30px;
}

header.client-header div.header-bar > a.addr:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.04);
}

header.client-header div.header-bar > a.addr.menu {
    background-image: url('../../assets/menu-icon.svg');
}

header.client-header div.header-bar > a.addr.back {
    background-image: url('../../assets/back-icon.svg');
    width: 30px;
    background-position: 5px center;
}

header.client-header div.header-bar img.profile {
    width: 40px;
    height: 40px;
    border-radius: 16px;
}

header.client-header div.header-bar img.profile:hover {
    opacity: 0.7;
}

header.client-header div.header-bar h1 {
    font-size: 21px;
}

header.client-header div.expanded-content {
    display: none;
    position: absolute;
    left: 0;
    top: 0px;
    right: 0px;
    bottom: 0;
    padding: 0;
}

header.client-header div.expanded-content div.retract-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

header.client-header div.expanded-content.open {
    display: block;
}

header.client-header div.expanded-content div.menu-popup,
header.client-header div.expanded-content div.user-popup {
    display: none;
    position: absolute;
    top: 0px;
    right: 0px;
    min-width: 250px;
    background-color: rgba(255, 255, 255, 0.96);
    box-shadow: -2px 3px 12px 0px rgba(0,0,0,0.27);
}

header.client-header div.expanded-content div.menu-popup {
    left: 0px;
    max-width: 250px;
    border-bottom-right-radius: 7px;
}

header.client-header div.expanded-content div.user-popup {
    right: 0px;
    border-bottom-left-radius: 7px;
}

header.client-header div.expanded-content div.user-popup.open,
header.client-header div.expanded-content div.menu-popup.open {
    display: block;
}

header.client-header div.expanded-content div.user-popup div.user {
    background-color: rgba(19, 124, 243, 0.04);
    padding: 6px 22px 4px 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 9px;
}

header.client-header div.expanded-content div.user-popup div.user h3 {
    font-weight: bold;
    font-size: 13px;
}

header.client-header div.expanded-content div.user-popup div.user figure {
    width: 40px;
    height: 40px;
    position: relative;
}

header.client-header div.expanded-content div.user-popup div.user figure img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
}

header.client-header div.expanded-content div.user-popup div.user figure div.change-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 16px;
    background-color: rgba(0, 0, 0, 0.24);
    background-image: url('../../assets/camera-icon.svg');
    background-repeat: no-repeat;
    background-position:  center;
    cursor: pointer;
}

header.client-header div.expanded-content div.user-popup div.user figure:hover div.change-overlay {
    background-color: rgba(0, 0, 0, 0.54);
}

header.client-header div.expanded-content div.user-popup ul.user-options {
    padding: 15px 22px;
    margin: 0;
    list-style: none;
    font-family: "Segoe UI semibold";
    font-size: 13px;
}

header.client-header div.expanded-content div.user-popup ul.user-options li {
    padding: 6px 0 6px 26px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.09);
    background-repeat: no-repeat;
    background-position: left center;
    cursor: pointer;
}

header.client-header div.expanded-content div.user-popup ul.user-options li:hover {
    color: rgb(8, 130, 184);
}

header.client-header div.expanded-content div.user-popup ul.user-options li:last-child {
    border-bottom: none;
}

header.client-header div.expanded-content div.user-popup ul.user-options li[name='profile'] {
    background-image: url('../../assets/profile-icon.svg');
}

header.client-header div.expanded-content div.user-popup ul.user-options li[name='settings'] {
    background-image: url('../../assets/settings-icon.svg');
}

header.client-header div.expanded-content div.user-popup ul.user-options li[name='password'] {
    background-image: url('../../assets/password-icon-black.svg');
}

header.client-header div.expanded-content div.user-popup ul.user-options li[name='help'] {
    background-image: url('../../assets/help-icon.svg');
}

header.client-header div.expanded-content div.user-popup ul.user-options li[name='logout'] {
    background-image: url('../../assets/logout-icon.svg');
}