section.songbook-images-edit-popup div.window {
    width: calc(100% - 20px);
    margin: 0 10px;
    max-width: 450px;
}

section.songbook-images-edit-popup div.contents {
    text-align: center;
}

section.songbook-images-edit-popup div.contents h3.title {
    margin: 25px 30px 0 30px;
    color: rgba(0,0,0,0.8);
    font-size: 18px;
}

section.songbook-images-edit-popup div.contents p.message {
    color: rgba(0,0,0,0.6);
    font-size: 14px;
    margin: 15px 30px 0 30px;
}

section.songbook-images-edit-popup div.contents > button.async-action-button.popup-button {
    width: auto;
    padding: 0 30px;
    margin: 20px auto 30px auto;
}

section.songbook-images-edit-popup div.contents div.uploads {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

section.songbook-images-edit-popup div.contents div.uploads section.file-uploader {
    display: block;
    width: calc(50% - 15px);
    margin: 0 0px 0 10px;
}