section.editable-song-player {
    width: auto;
}


section.editable-song-player div.song-address {
    padding: 8px 22px;
    background-color: rgba(0,0,0,0.55);
    display: grid;
    grid-template-columns: 30px 120px 1fr;
    align-items: center;
}

section.editable-song-player div.song-address span.icon {
    display: block;
    background-image: url('../../assets/youtube-icon.svg');
    background-repeat: no-repeat;
    background-size: 22px;
    height: 23px;
    background-position: center left;
    opacity: 0.7;
}

section.editable-song-player div.song-address span.prefix {
    font-weight: bold;
    font-size: 18px;
    opacity: 0.7;
}

section.editable-song-player div.song-address em.videoId {
    font-weight: bold;
    font-size: 18px;
}

section.editable-song-player.loading div.song-address span.icon {
    background-image: url('../../assets/ball-loader.svg');
}