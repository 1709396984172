section.song-header.editable span.details span.buttons {
    display: flex;
    flex-flow: row;
}

section.song-header.editable span.buttons span.delete-button {
    width: 40px;
    height: 45px;
    background-image: url('../../assets/trash-white-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px 15px;
    cursor: pointer;
    opacity: 0.7;
}

section.song-header.editable.loading span.buttons span.delete-button {
    background-image: url('../../assets/ball-loader.svg');
    background-size: 18px 21px;
    visibility: visible !important;
}