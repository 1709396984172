main.registered-songbooks {
    max-width: unset !important;
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    position: relative;
    overflow: hidden;
    background-color: #000;
}


main.registered-songbooks section.content {
    position: relative;
    z-index: 2;
    min-height: 100%;
    overflow: auto;
}


main.registered-songbooks section.background {
    position: absolute;
    background-image: url('../../assets/songbooks-bg.jpg');
    background-color: #000;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: blur(23px);
    opacity: 0.8;
    left: -20px;
    right: -20px;
    top: -20px;
    bottom: -20px;
    z-index: 0;
}

main.registered-songbooks div.songbooks-content {
    display: block;
    margin: 0 5px 0 5px;
    height: calc(100vh - 75px);
    padding: 5px;
    overflow-y: auto;
}

main.registered-songbooks section.content header.client-header {
    margin: 0 22px;
    color: #000 !important;
}

@media only screen and (max-device-width : 640px) and (max-device-width: 768px) {
    main.registered-songbooks div.songbooks-content {
        height: calc(100vh - 120px);
    }
}