section.song-header {
    margin: 0 22px 15px 22px;
}

section.song-header span.details {
    display: grid;
    grid-template-columns: 1fr 40px;
}

section.song-header span.details span.song-info > em {
    display: block;
}

section.song-header span.details em.title {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    border-bottom: solid 1px rgba(255,255,255, 0.15);
    padding-bottom: 4px;
}

section.song-header span.details em.composer {
    padding-top: 2px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 13px;
    color: rgba(255,255,255, 0.74);
}