html, body, #root, #content-wrapper {
    height: 100%;
}

body {
    overflow: auto;
}

div#content-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}