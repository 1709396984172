section.song-list.editable li.add-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    cursor: pointer;
    position: relative;
}

section.song-list.editable li.add-button span.loading-icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    z-index: 2;
    align-items: center;
    justify-content: center;
}

section.song-list.editable.loading li.add-button span.loading-icon {
    display: flex;
}

section.song-list.editable li.add-button span.loading-icon::before {
    display: block;
    content: " ";
    background-color: #f6f4f4;
    width: 40px;
    height: 40px;
    border-radius: 34px;
    margin: -5px 0 0 -6px;
    background-image: url('../../assets/ball-loader-silver.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

section.song-list.editable li.add-button button {
    background-color: transparent;
    border: none;
    width: 100%;
    height: 74px;
    background-image: url('../../assets/add-button.svg');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    cursor: pointer;
    opacity: 0.9;
}

section.song-list.editable li.add-button:hover {
    background-color: rgba(255,255,255,0.05);
}

section.song-list.editable li.add-button:hover button {
    opacity: 1;
}